<template>
  <b-card id="card-activity-routine">
    <div class="container-card-header">
      <span class="title">
        {{ $t('IntegrationPage.RoutineBehaviour') }}
      </span>
    </div>
    <b-col>
      <b-row>
        <b-form-group
          :label="$t('IntegrationPage.QuantityPerLot2')"
          class="col-12 p-0 m-0"
        >
          <b-form-input
            v-model="quantity"
            @keydown="filterInput($event)"
            :placeholder="'00'"
          />
        </b-form-group>
      </b-row>
    </b-col>
  </b-card>
</template>

<script lang="js">
  import CloseIcon from '@/assets/images/icons/close-icon-small-2.svg';
  import Ripple from 'vue-ripple-directive';
  import { mapGetters, mapMutations } from 'vuex';
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCard,
    VBToggle
  } from 'bootstrap-vue';

  export default {
    components: {
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BCard,
      CloseIcon
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },
    props: {
      editingData: {
        type: Object,
        default: {}
      }
    },
    watch: {
      flagCleanFields(v) {
        if (v) this.cleanFields();
      },
      flagActivityButton(v) {
        if (v && this.flagLotsIbox && this.flagIntegrationAction) this.saveData();
      },
      flagAfterMapIboxs(v) {
        if (!v) this.cleanFields();
        if (v && this.flagEditActivity && this.flagLotsIbox) this.setDataForEditing();
      }
    },
    data() {
      return {
        currentSite: localStorage.getItem('currentSite'),
        quantity: null
      };
    },
    mounted() {
      if (this.flagAfterMapIboxs && this.flagEditActivity && this.flagLotsIbox)
        this.setDataForEditing();
    },
    computed: {
      ...mapGetters('flags_controller_pipelines', [
        'flagActivityButton',
        'flagCleanFields',
        'flagEditActivity',
        'flagAfterMapIboxs',
        'flagLotsIbox',
        'flagIntegrationAction'
      ])
    },
    methods: {
      ...mapMutations('flags_controller_pipelines', ['UPDATE_FLAG_LOTS_VALIDATION']),
      filterInput(event) {
        let input = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        let keyPressed = event.key;
        let keyCode = event.keyCode ? event.keyCode : event.which;

        if (!input.includes(keyPressed) && keyCode != 8) {
          event.preventDefault();
        }
      },
      saveData() {
        let data = {
          batchQuantity: this.quantity
        };

        this.$emit('setValue', data);

        this.UPDATE_FLAG_LOTS_VALIDATION(true);
      },
      setDataForEditing() {
        this.quantity = Number(this.editingData.activity.batchQuantity?.batchQuantity);
      },
      cleanFields() {
        this.quantity = null;
      }
    }
  };
</script>

<style lang="scss">
  #card-activity-routine {
    overflow-x: visible;

    .card-body {
      padding: 16px;
    }

    .container-card-header {
      flex-direction: row;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #4c4541;
      }
      .title-list {
        margin: 16px 0;
      }
    }
  }
</style>
