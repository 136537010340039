<template>
  <b-card id="card-activity-identification">
    <div class="container-card-header">
      <span class="title">
        {{ $t('IntegrationPage.IdentifierKeys') }}
      </span>
    </div>

    <div cols="12">
      <VSelectChips
        :label="$t('IntegrationPage.Keys')"
        :labelFor="'keys'"
        class="v-select-new-scheduling"
        :placeholder="$t('WorkcenterList.Select')"
        :valueSelectMultiple="selectedOptions"
        :options="fieldMappingSelected"
        :stateField="flagValidKey"
        :key="`select-chips-pipeline-keys${controlRender}`"
        @updateData="(value) => (selectedOptions = value)"
      >
        <span
          class="is-invalid-span"
          v-if="flagValidKey == false"
          >{{ $t('RequiredField') }}</span
        >
      </VSelectChips>
    </div>
  </b-card>
</template>

<script lang="js">
  import VSelectChips from '@/@core/components/v-select/vSelectChips.vue';
  import Ripple from 'vue-ripple-directive';
  import { mapGetters, mapMutations } from 'vuex';
  import { BCard, VBToggle } from 'bootstrap-vue';

  export default {
    components: {
      BCard,
      VSelectChips
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },
    props: {
      editingData: {
        type: Object,
        default: {}
      },
      fieldMappingSelected: {
        type: Array,
        default: []
      }
    },
    watch: {
      flagCleanFields(v) {
        if (v) this.cleanFields();
      },
      fieldMappingSelected() {
        this.selectedOptions = [];
        this.controlRender++;
      },
      flagActivityButton(v) {
        if (v && this.flagIdentificationKeyIbox && this.flagIntegrationAction) this.saveData();
      },
      flagAfterMapIboxs(v) {
        if (!v) this.cleanFields();
        if (v && this.flagEditActivity && this.flagIdentificationKeyIbox) this.setDataForEditing();
      }
    },
    mounted() {
      if (this.flagAfterMapIboxs && this.flagEditActivity && this.flagIdentificationKeyIbox)
        this.setDataForEditing();
    },
    computed: {
      ...mapGetters('flags_controller_pipelines', [
        'flagActivityButton',
        'flagCleanFields',
        'flagEditActivity',
        'flagAfterMapIboxs',
        'flagIdentificationKeyIbox',
        'flagIntegrationAction'
      ])
    },
    data() {
      return {
        currentSite: localStorage.getItem('currentSite'),
        selectedOptions: [],
        flagValidKey: null,
        controlRender: 0,
        options: []
      };
    },
    methods: {
      ...mapMutations('flags_controller_pipelines', ['UPDATE_FLAG_IDENTIFICATION_KEYS_VALIDATION']),
      validateFields() {
        this.flagValidKey = this.selectedOptions.length > 0 ? null : false;
        let validate = this.flagValidKey == null ? true : false;
        this.UPDATE_FLAG_IDENTIFICATION_KEYS_VALIDATION(validate);

        return validate;
      },
      saveData() {
        let validate = this.validateFields();
        if (validate) {
          let data = this.selectedOptions.map((item) => {
            return { field_name: item.label, field_type: item.type, value: item.value };
          });
          this.$emit('setValue', data);
        }
      },
      cleanFields() {
        this.selectedOptions = [];
        this.flagValidKey = null;
      },
      setDataForEditing() {
        this.controlRender++;
        setTimeout(() => {
          this.selectedOptions = this.fieldMappingSelected.filter((item) =>
            this.editingData.activity.identificationKeys.find((f) => f.value == item.value)
          );
        }, 3100);
      }
    }
  };
</script>

<style lang="scss">
  #card-activity-identification {
    overflow-x: visible;

    .card-body {
      padding: 16px 16px 0px 16px;
    }

    input::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #cfc4be;
    }

    label,
    legend {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0 !important;
      padding-bottom: 4px !important;
    }

    .is-invalid-span {
      font-weight: 300;
      font-size: 10px;
      line-height: 14px;
      color: #d32f2f;
      text-align: left;
    }

    .container-card-header {
      flex-direction: row;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #4c4541;
      }
      .title-list {
        margin: 16px 0;
      }
    }
  }
</style>
